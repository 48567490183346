import React from 'react';
import Layout from '../../components/layouts';
import Banner from '../../components/common/Banner'
import TeamGrid from '../../components/about/the-team/TeamGrid'
import StaffGrid from '../../components/about/the-team/StaffGrid'
import SEO from '../../components/common/SEO';

export const query = graphql`
  query TeamQuery {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`
export default ({ data }) => {
  // BANNER PROPS
  const title = () => (
    <div>
      <span>Meet </span>The
      <br /> Workhub <span>Champions</span>
    </div>
  )

  const seo = {
    title: "Workhub | Our Team | EHS Management Software Team",
    description: "Meet the champions of Workhub and the heart of our company.",
    image: "/social-thumbs/thumb-team.jpg",
    // pathname,
    // article
  }
  const bannerProps = {
    title: title,
    subtitle: "About > Our Team",
    buttonLeft: {
      text: "Our Story",
      link: "/about/story/",
    },
    buttonRight: {
      text: "Partners",
      link: "/resources/partners/",
    },
  }
  const subscribeBannerProps = {
    headline: "Want to join the Workhub family?",
    content:
      "We are always on the lookout for talented and motivated professionals that would like to dive in the compliance world.",
  }
  return (
    <Layout subscribeBannerProps={subscribeBannerProps}>
      <SEO {...seo} {...data.site} />
      <Banner {...bannerProps} />
      {/* <TeamGrid /> */}
      <StaffGrid/>
    </Layout>
  )
}
